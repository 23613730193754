// content
import { CASES } from 'content/Cases/Cases'
import { generateHerosection } from 'content/Herosection/Herosection'
// translate
import { translate } from '../i18n'
// pages
import AboutUs from 'pages/AboutUsPage/AboutUsPage'
import Blog from 'pages/Blog/Blog'
import BlogArticle from 'pages/BlogArticle/BlogArticle'
import CaseStudy from 'pages/CaseStudy/CaseStudy'
import FormSuccess from 'pages/FormSuccess/FormSuccess'
import GDPR from 'pages/GDPR/GDPR'
import Home from 'pages/Home/Home'
import LogoPage from 'pages/LogoPage/LogoPage'
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy'
import Solution from 'pages/Solution/Solution'
import Solutions from 'pages/Solutions/Solutions'
// utils
import { getPath, scrollToFooter } from 'utils/functions'

/**
 * Note that when specifying routes, there is a hierarchial order of handling user interaction.
 * 1. path with component => 2. path => 3. onClick
 */
export interface IRoute {
  /**
   * Component (whole page) of the given route.
   */
  component?: any
  contactRoute?: boolean
  /**
   * Opens the link in the new tab.
   */
  external?: boolean
  hideFromMenu?: boolean
  /**
   * Name of the route (sideMenu).
   */
  name?: string
  /**
   * The title of the page (HTML title).
   */
  pageTitle?: string
  /**
   * Path to the given page.
   */
  path?: string
  props?: any
  /**
   * Page description for SEO purpose.
   */
  seoDescription?: string
  /**
   * Keywords for SEO.
   */
  seoKeyWords?: string[]
  /**
   * Site name for SEO.
   */
  seoSiteName?: string
  /**
   * Custom onClick event (when clicked in nav/menu).
   */
  onClick?(onCloseMenu?: () => void): void
}

export const generateRoutes = (): IRoute[] => [
  {
    component: Home,
    name: translate.i18n.HOME,
    pageTitle: translate.i18n.PAGE_TITLE.HOME,
    path: getPath(translate.i18n.ROUTES.home),
    seoDescription: translate.i18n.SEO_DESCRIPTIONS.home,
    seoKeyWords: translate.i18n.SEO_KEYWORDS.home,
    seoSiteName: translate.i18n.SEO_SITENAMES.home,
  },
  {
    component: FormSuccess,
    path: `/${translate.i18n.CONTACT_FORM.URL}`,
  },
  {
    component: AboutUs,
    name: translate.i18n.ABOUT_US,
    pageTitle: translate.i18n.PAGE_TITLE.ABOUT_US,
    path: `/${translate.i18n.ROUTES.aboutUs}`,
    seoDescription: translate.i18n.SEO_DESCRIPTIONS.aboutUs,
    seoKeyWords: translate.i18n.SEO_KEYWORDS.aboutUs,
    seoSiteName: translate.i18n.SEO_SITENAMES.aboutUs,
  },
  {
    component: Solutions,
    name: translate.i18n.SOLUTIONS,
    pageTitle: translate.i18n.PAGE_TITLE.SOLUTIONS,
    seoDescription: translate.i18n.SEO_DESCRIPTIONS.solutions,
    seoKeyWords: translate.i18n.SEO_KEYWORDS.solutions,
    seoSiteName: translate.i18n.SEO_SITENAMES.solutions,
    path: `/${translate.i18n.ROUTES.solutions}`,
  },
  ...CASES.filter((caseItem) => !caseItem.externalUrl).map((caseItem) => ({
    component: Solution,
    props: { solution: caseItem },
    name: '',
    pageTitle: caseItem.metatitle || caseItem.title,
    seoDescription: caseItem.seoDescription || caseItem.shortText || caseItem.text,
    path: `/${translate.i18n.ROUTES.solutions}/${caseItem.url}`,
  })),
  ...CASES.flatMap((caseItem) =>
    generateHerosection(caseItem.url)
      .filter((hero) => hero.url && hero.subUrl)
      .map((hero) => ({
        component: CaseStudy,
        props: { caseStudy: hero },
        name: '',
        pageTitle: `${hero.seoTitle || hero.title} - ${translate.i18n.CASE_STUDY}`,
        seoDescription: hero.text,
        path: `/${translate.i18n.ROUTES.solutions}/${caseItem.url}/${translate.i18n.ROUTES.caseStudy}/${hero.url}`,
      }))
  ),
  {
    component: GDPR,
    hideFromMenu: true,
    name: translate.i18n.GDPR.TITLE,
    pageTitle: translate.i18n.GDPR.TITLE,
    path: `/${translate.i18n.GDPR.URL}`,
  },
  {
    component: PrivacyPolicy,
    hideFromMenu: true,
    name: translate.i18n.PRIVACY_POLICY,
    pageTitle: translate.i18n.PRIVACY_POLICY,
    path: `/${translate.i18n.PRIVACY_POLICY_URL}/:app`,
  },
  {
    component: Blog,
    name: translate.i18n.BLOG,
    pageTitle: translate.i18n.BLOG,
    seoDescription: translate.i18n.SEO_DESCRIPTIONS.blog,
    path: `/${translate.i18n.ROUTES.blog}`,
  },
  {
    component: BlogArticle,
    path: `/${translate.i18n.ROUTES.blog}/*`,
    hideFromMenu: true,
  },
  {
    contactRoute: true,
    name: translate.i18n.CONTACT,
    pageTitle: '',
    onClick: (closeMenu) => {
      if (closeMenu) {
        closeMenu()
        setTimeout(() => {
          scrollToFooter()
        }, 6 * 75)
      } else {
        scrollToFooter(true)
      }
    },
  },
  {
    component: LogoPage,
    path: `/${translate.i18n.ROUTES.logo}`,
    hideFromMenu: true,
    name: translate.i18n.PAGE_TITLE.LOGO,
    pageTitle: translate.i18n.PAGE_TITLE.LOGO,
    seoSiteName: translate.i18n.PAGE_TITLE.LOGO,
    seoDescription: translate.i18n.SEO_DESCRIPTIONS.logo,
  },
  {
    component: Home,
    hideFromMenu: true,
    name: translate.i18n.HOME,
    pageTitle: translate.i18n.PAGE_TITLE.HOME,
    path: '/',
    seoDescription: translate.i18n.SEO_DESCRIPTIONS.home,
    seoKeyWords: translate.i18n.SEO_KEYWORDS.home,
    seoSiteName: translate.i18n.SEO_SITENAMES.home,
  },
]
