// core
import React, { useEffect, useState } from 'react'
// libraries
import cx from 'classnames'
import { useParams } from 'react-router-dom'
// components
import { Layout } from 'layouts/Layout'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
// styles
import css from './PrivacyPolicy.module.scss'
import { getPath, goTo } from 'utils/functions'

export default function PrivacyPolicy() {
  const [shouldAnimateFooter, setShouldAnimateFooter] = useState<boolean>(false)

  const { app } = useParams<{ app?: string }>()

  useEffect(() => {
    if (!app || !['sama-app'].includes(app)) {
      goTo(getPath('404'))
    }
  }, [app])

  return (
    <Layout hideBackdrop footerAnimate={shouldAnimateFooter}>
      <main className={cx(css.wrapper, 'container')}>
        <h1>Slovaktual SAMA</h1>
        <p>
          Provided by <strong>SLOVAKTUAL s.r.o.</strong>, developed by{' '}
          <strong>ambitas, s.r.o.</strong>
        </p>
        <br />
        <br />
        <h1>Privacy Policy for Slovaktual SAMA</h1>
        <p>
          <strong>Effective Date:</strong> 14.1.2025
        </p>
        <p>
          Thank you for using Slovaktual SAMA ("the App"), owned and operated by SLOVAKTUAL s.r.o..
          This Privacy Policy explains how we collect, use, and protect your information when you
          use the App. By using the App, you agree to the terms of this Privacy Policy.
        </p>

        <hr />

        <h2>1. Information We Collect</h2>
        <p>We collect the following types of information when you use the App:</p>
        <ul>
          <li>
            <strong>Personal Information</strong>: Such as name, email address, and phone number.
          </li>
          <li>
            <strong>Location Data</strong>: Either approximate or precise location, based on your
            choice.
          </li>
          <li>
            <strong>Device Information</strong>: Includes device ID and operating system name to
            ensure compatibility and app functionality.
          </li>
          <li>
            <strong>Media Files</strong>: Photos that you upload within the App to support your
            orders or related activities.
          </li>
        </ul>

        <hr />

        <h2>2. How We Collect Information</h2>
        <p>We collect data in the following ways:</p>
        <ul>
          <li>
            <strong>User Input</strong>: Information that you provide directly, such as during
            account creation or uploading media.
          </li>
          <li>
            <strong>Automated Tracking</strong>: Information such as device details is collected
            automatically when you log into your account.
          </li>
        </ul>

        <hr />

        <h2>3. How We Use Your Information</h2>
        <p>We use the collected information to:</p>
        <ul>
          <li>Provide and enhance the App’s functionality.</li>
          <li>Comply with applicable legal and regulatory requirements.</li>
          <li>
            Support internal company processes related to order management and delivery tracking.
          </li>
        </ul>

        <hr />

        <h2>4. Sharing Your Information</h2>
        <p>We do not share your data with third parties.</p>

        <hr />

        <h2>5. User Rights and Data Management</h2>
        <ul>
          <li>
            <strong>Data Access and Deletion</strong>: Users cannot manage or delete their data
            directly within the App. If you wish to access, update, or delete your data, you can
            contact us at <a href="mailto:support@ambitas.org">support@ambitas.org</a>.
          </li>
          <li>
            <strong>Opt-Out Options</strong>: The App does not directly provide an option to opt out
            of data collection. For concerns or requests, please reach out to our support team.
          </li>
        </ul>

        <hr />

        <h2>6. Data Security</h2>
        <p>We prioritize protecting your information by:</p>
        <ul>
          <li>Storing data in secured systems to prevent unauthorized access.</li>
          <li>
            Using encrypted data transfer methods to ensure your data remains protected during
            communication.
          </li>
        </ul>

        <hr />

        <h2>7. Compliance with GDPR</h2>
        <p>We comply with the General Data Protection Regulation (GDPR). You have the right to:</p>
        <ul>
          <li>Request access to your data.</li>
          <li>Correct inaccuracies.</li>
          <li>Request the deletion of your personal information.</li>
        </ul>
        <p>
          For assistance, contact <a href="mailto:support@ambitas.org">support@ambitas.org</a>.
        </p>

        <hr />

        <h2>8. Data Retention and Deletion Policy</h2>
        <p>
          We retain personal and sensitive user data only for as long as necessary to fulfill the
          purposes outlined in this Privacy Policy, or as required to comply with legal and
          regulatory obligations. Once the data is no longer needed, we securely delete or anonymize
          it. If you wish to request the deletion of your data, please contact us at{' '}
          <a href="mailto:support@ambitas.org">support@ambitas.org</a>.
        </p>

        <hr />

        <h2>9. Updates to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Users will be informed about
          significant changes via email. We recommend that you review the updated Privacy Policy
          after any new app version release to stay informed about how your data is handled.
        </p>

        <hr />

        <h2>10. Contact Information</h2>
        <p>
          If you have questions or concerns about this Privacy Policy or your data, please contact
          us:
        </p>
        <ul>
          <li>
            <strong>Email</strong>: <a href="mailto:support@ambitas.org">support@ambitas.org</a>
          </li>
        </ul>

        <hr />
      </main>

      <ScrollBar
        onLoadThreshold={90}
        onUnloadThreshold={10}
        onLoadChanged={setShouldAnimateFooter}
      />
    </Layout>
  )
}
